import { useContext, useEffect, useState } from 'react';
import { LinearProgress, Stack, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import UserContext from '../contexts/UserContext';

import { updateProgram, useFetchProgram } from '../services/programs_service';
import { uploadFile } from '../utils/fileUploader';
import Notification from '../components/notification';
import PackageSection from '../components/package-section';
import SessionSection from '../components/session-section';
import ProgramSection from '../components/program-section';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    margin: '1rem',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    margin: '2rem',
  },
}));

export const StyledForm = styled('form')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const CURRENCY_OPTIONS = ['Rub', 'Dollar', 'Euro', 'BEL Rub'];
export const TYPE_OPTIONS = ['Offline', 'Online'];

export default function EditProgramPage() {
  const { id: programId } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { program, isLoading: isLoadingProgram } = useFetchProgram(programId);

  const [snackData, setSnackData] = useState({
    isOpen: false,
    isSuccess: true,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [programFormError, setProgramFormError] = useState(null);

  const sessionsState = useState([]);
  const packagesState = useState([]);
  const programDetailsState = useState({
    name: '',
    type: TYPE_OPTIONS[0],
    group: '',
    language: 'English',
    description: '',
    category_id: null,
    sub_category_id: '',
    coaches_id: user?.userRoles.includes('COACH') ? [user?.id] : [],
    currency: CURRENCY_OPTIONS[0],
    tags: []
  });

  const [sessions] = sessionsState;
  const [programDetails] = programDetailsState;

  useEffect(() => {
    if (program) {
      const { sessions, packages, ...programDetails } = program;

      programDetailsState[1](programDetails);
      sessionsState[1](sessions);
      packagesState[1](packages);
    }
  }, [program]);

  function attachNonFormFields(data) {
    data.updated_at = Timestamp.now();
    return data;
  }

  function checkDataValidity() {
    const data = programDetails;
    if (!data.name) return t('Enter the program name');
    if (!data.category_id) return t('Select a category');
    // if (!data.sub_category_id) return t('Select a category and a sub-category');
    if (!data.coaches_id) return t('Select one coach at least');
    if (!data.language) return t('Enter the program language');
    if (!data.description) return t('Enter the program description');
    if (!data.currency) return t('Enter the program currency');
    return null;
  }

  const submitForm = async () => {
    const _error = checkDataValidity();
    if (_error) {
      setProgramFormError(_error);
      return;
    }

    const payload = attachNonFormFields(programDetails);

    setIsUploading(true);

    setProgramFormError(null);
    
    updateProgram(programId, payload, sessions, packagesState[0])
      .catch((error) => {
        console.log(error);
        setSnackData((prev) => ({ ...prev, isSuccess: false }));
      })
      .finally(() => {
        setIsUploading(false);
        setSnackData((prev) => ({ ...prev, isOpen: true }));
      });
  };

  const handleCloseSnack = () => {
    setSnackData((prev) => ({ ...prev, isOpen: false }));
  };

  return isLoadingProgram ? (
    <LinearProgress />
  ) : (
    <>
      <Helmet>
        <title>{`${t('edit_program')} | ${program?.name}`}</title>
      </Helmet>
      <StyledRoot>
        <Stack
          gap="4rem"
          justifyContent="center"
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'row' }}
          width="100%"
        >
          <ProgramSection payloadState={programDetailsState} error={programFormError} isUpdate />
          <Stack width={{ xs: '100%', sm: 'auto' }}>
            <Stack paddingBottom="2rem" alignItems="flex-end" width="100%" display={{ xs: 'none', sm: 'flex' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                onClick={submitForm}
                loading={isUploading}
                disabled={isUploading}
              >
                {t('Update')}
              </LoadingButton>
            </Stack>

            <SessionSection currency={programDetails.currency} sessionsState={sessionsState} isUp />
            <PackageSection currency={programDetails.currency} sessions={sessions} packagesState={packagesState} />

            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              onClick={submitForm}
              loading={isUploading}
              disabled={isUploading}
              sx={{ display: { xs: 'initial', sm: 'none' }, marginTop: 4 }}
              fullWidth
            >
              {t('Update')}
            </LoadingButton>
          </Stack>
        </Stack>

        <Notification
          open={snackData.isOpen}
          success={snackData.isSuccess}
          message={t(
            snackData.isSuccess ? 'Program Successfully Updated' : 'Error updating program, please try again later'
          )}
          onClose={handleCloseSnack}
        />
      </StyledRoot>
    </>
  );
}
