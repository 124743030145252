import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Index({ file, onClose, onSend }) {
    const { t } = useTranslation();
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);
    
    return (
        <Dialog open={file !== null} onClose={onClose}>
            <DialogTitle id="fcm-dialog-title">{t('send_image')}</DialogTitle>
            <DialogContent>
                {imageSrc && (
                    <div style={{ marginTop: '16px' }}>
                        <img
                            src={imageSrc}
                            alt="Preview"
                            style={{ maxWidth: '100%', height: 'auto', borderRadius: '4px' }}
                        />
                    </div>
                )}
                <DialogActions>
                    <Button autoFocus onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={onSend}>
                        {t('Send')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default Index