import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { CancelOutlined } from "@material-ui/icons";
import { StreamCall, useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import { formatISO9075 } from "date-fns";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import AVCallContext from "../../contexts/AVCallContext";

CallBar.propTypes = {
    call: PropTypes.object
};
export default function CallBar({ call }) {
    console.log("CallBar Top | Call", call)
    return (
        <Collapse in={Boolean(call)}>
            {
                call &&
                <StreamCall call={call} >
                    <CallBarContent />
                </StreamCall>
            }
        </Collapse>
    );
}

function CallBarContent() {
    const { currentCall, setCurrentCall } = useContext(AVCallContext);
    const chatCall = useCall();
    const {
        useCallSession,
        useCallStartedAt,
        useCallStartsAt,
        useParticipantCount
    } = useCallStateHooks();

    const startedAt = useCallStartedAt();
    const session = useCallSession();
    const startsAt = useCallStartsAt();


    const handleClickJoin = () => {
        console.log("Joining chatCall");
        setCurrentCall(chatCall);
        chatCall.join()
            .catch((e) => {
                console.error("Error joining chatCall", e);
                setCurrentCall(null);
            });
    };

    const handleCancelScheduledCall = () => {
        chatCall.endCall();
    };
    
    const isOnGoingCall = currentCall?.id === chatCall.id;
    console.log("Call bar | scheduled", startsAt, startedAt, chatCall, dayjs().isBefore(startsAt), isOnGoingCall)

    if (startsAt && dayjs().isBefore(startsAt) && !startedAt) {
        return <CallBarScheduled
            startsAt={startsAt}
            onCancelCall={handleCancelScheduledCall}
            onClickJoin={handleClickJoin}
        />
    }

    return <CallBarOnGoing
        isOnGoingCall={isOnGoingCall}
        onClickJoin={handleClickJoin}
        startedAt={startedAt}
        session={session}
    />

}

CallBarOnGoing.propTypes = {
    isOnGoingCall: PropTypes.bool,
    onClickJoin: PropTypes.func.isRequired,
    startedAt: PropTypes.object,
    session: PropTypes.object,
};
function CallBarOnGoing({ isOnGoingCall, onClickJoin, startedAt, session }) {
    const call = useCall();
    const [duration, setDuration] = useState("-");
    const [participantCount, setParticipantCount] = useState(session.participants.length);

    const getCaption = () => {
        const elements = [];
        if (startedAt) elements.push(duration);
        elements.push(`${participantCount} online`);
        if (participantCount === 0) elements.push(`Ending...`);

        return elements.join(' | ');
    }

    useEffect(() => {
        const unsubscribes = [
            call.on("call.session_participant_count_updated", (event) => {
                const count = Object.values(event.participants_count_by_role).reduce((a, b) => a + b, 0);
                setParticipantCount(count);
            })
        ];

        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
        }
    }, [call])

    useEffect(() => {
        if (!startedAt) {
            return () => { };
        }
        const interval = setInterval(() => {
            setDuration(formatISO9075(Date.now() - startedAt, { representation: 'time' }));
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [startedAt]);

    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" justifyContent="space-between" alignItems="center" paddingInline="1rem" sx={{ backgroundColor: blueGrey[50] }}>
            <Box>
                <Typography variant="body1">{"Call in progress"}</Typography>
                <Typography variant="caption">{getCaption()}</Typography>
            </Box>
            <Box display="flex" gap="1rem" justifyContent="flex-end">
                <Button variant="contained" color="success" disabled={isOnGoingCall} onClick={onClickJoin}>Join Call</Button>
            </Box>
        </Box>
    )
}

CallBarScheduled.propTypes = {
    startsAt: PropTypes.object.isRequired,
    onCancelCall: PropTypes.func.isRequired,
    onClickJoin: PropTypes.func.isRequired
};
function CallBarScheduled({ startsAt, onCancelCall, onClickJoin }) {
    const [startsIn, setStartsIn] = useState(dayjs(startsAt).calendar());
    const [duration, setDuration] = useState(dayjs(dayjs(startsAt).diff()).utc().format('HH:mm:ss'));
    const [isTimerEnd, setIsTimerEnd] = useState(false);

    useEffect(() => {
        if (!startsAt) {
            setIsTimerEnd(true);
            return () => { };
        }
        const interval = setInterval(() => {
            const now = dayjs(); 
            
            if (now.isAfter(startsAt)) {
                clearInterval(interval);
                setIsTimerEnd(true);
            }

            setStartsIn(dayjs(startsAt).calendar());

            const durationMillis = dayjs(startsAt).diff(now);
            const durationFormatted = dayjs(durationMillis).utc().format('HH:mm:ss');
            setDuration(durationFormatted);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [startsAt]);

    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" justifyContent="space-between" alignItems="center" paddingInline="1rem" sx={{ backgroundColor: blueGrey[50] }}>
            <Box>
                <Typography variant="body1">{"Scheduled call"}</Typography>
                <Typography variant="caption">{startsIn}</Typography>
            </Box>
            <Box display="flex" gap="1rem" justifyContent="flex-end">
                <Button variant="contained" color="success" disabled={!isTimerEnd} onClick={onClickJoin}>{isTimerEnd ? "Join" : duration}</Button>
                <IconButton aria-label="delete" size="small" color="error" onClick={onCancelCall}>
                    <CancelOutlined fontSize="inherit" />
                </IconButton>
            </Box>
        </Box>
    )
}