import { initializeApp } from '@firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyBfA1sTSFR_SaRu00XaeF1l0SpnchltsxA',
    authDomain: 'mmcx-8a776.firebaseapp.com',
    databaseURL: 'https://mmcx-8a776.firebaseio.com',
    projectId: 'mmcx-8a776',
    storageBucket: 'mmcx-8a776.appspot.com',
    messagingSenderId: '913530003143',
    appId: '1:913530003143:web:94631ab24f284a6946e8e2',
    measurementId: 'G-measurement-id',
};

const authApp = initializeApp(firebaseConfig, 'auth');
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = getFirestore(app);
const auth = getAuth(app)
const userCreationAuth = getAuth(authApp);

onMessage(messaging, ({ data, ...props }) => {
    console.log("Push notification received", data, props);
    if (data.type === 'call.ring') return;

    new Notification(data.title, {
        body: data.message,
    });
});

export { db, auth, messaging, userCreationAuth, onAuthStateChanged };
