import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getToken } from 'firebase/messaging';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import UserContext from '../../contexts/UserContext';
import { messaging } from '../../firebaseConfig';
import { savePushNotificationToken } from '../../services/users_service';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const userId = user ? user.id : null;

  useEffect(() => {
    if (userId === null) return;
    
    getToken(messaging, { vapidKey: "BLOWT2ypUPPJ9Uip88ooTy1KsfdZy4DhFDuNnSA2tUoqFMpagl1fL5BouG8R2b112lgmUoOioyEGNutId6luZ1M" })
      .then((currentToken) => {
        if (currentToken) {
          savePushNotificationToken(userId, currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }, [userId]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
