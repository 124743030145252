import { OpenInNew } from "@material-ui/icons";
import { Download } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

function Index({ url, onClose }) {
    const { t } = useTranslation();

    const onDownload = async () => {
        if (!url || typeof url !== 'string') {
            console.error('Invalid image URL');
            return;
        }

        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch image: ${response.statusText}`);

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = url.split('/').pop() || 'download';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error during download:', error);
        }
    }

    const onOpenInNew = () => {
        const a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.click();
    }

    return (
        <Dialog open={url} onClose={onClose}>
            <DialogContent>
                {url && (
                    <div style={{ marginTop: '16px' }}>
                        <img
                            src={url}
                            alt="Chat pic"
                            style={{ maxWidth: '100%', height: 'auto', borderRadius: '4px' }}
                        />
                    </div>
                )}
                <DialogActions>
                    <Button autoFocus onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                    <IconButton autoFocus onClick={onDownload}>
                        <Download />
                    </IconButton>
                    <IconButton onClick={onOpenInNew}>
                        <OpenInNew />
                    </IconButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default Index