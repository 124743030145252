import { MoreVert, PeopleOutline, VideoCallOutlined } from "@material-ui/icons";
import { Grow, IconButton, Menu, MenuItem } from "@mui/material";
import { useStreamVideoClient } from "@stream-io/video-react-sdk";
import { shape, string, any, array, bool } from "prop-types";
import { useContext, useState } from "react";
import { v4 } from "uuid";
import CallBar from "../call-layer/call-bar";
import MembersManagmentDialog from "./MembersManagmentDialog";
import useChatCall from "../../hooks/useChatCall";
import { upsertUsers } from "../../services/call_service";
import UserContext from "../../contexts/UserContext";

ChatRightHeader.propTypes = {
    collectionName: string,
    chat: shape({
        id: string,
        name: string,
        callInfos: shape({
            id: string,
            start_at: any
        })
    }),
    members: array
};
export default function ChatRightHeader({ collectionName, chat, members }) {
    const { user } = useContext(UserContext);
    const client = useStreamVideoClient();
    const chatCall = useChatCall(chat.id);

    const [callMenuAnchor, setCallMenuAnchor] = useState(null);
    const callMenuOpen = Boolean(callMenuAnchor);
    const [membersDialogOpen, setMembersDialogOpen] = useState(false);

    const handleCallMenuClose = () => setCallMenuAnchor(null);

    const handleStartCallClick = (type) => {
        const _callId = v4();
        const call = client.call('default', _callId);

        call.getOrCreate({
            ring: true,
            data: {
                members: members.map((m) => ({ user_id: m.id })),
                custom: {
                    name: "Voip call",
                    chatId: chat.id,
                    chatName: chat.name
                },
                video: type === 'video',
            }
        })
            .catch((error) => {
                console.error("Error starting call", error);

                if (error.code === 4) {
                    upsertUsers(members.map((m) => ({ id: m.id, name: m.name, image: m.image })))
                        .then(() => {
                            console.log("ChatRightHeader | Users upserted");
                            handleStartCallClick(type);
                        })
                        .catch((error) => console.error("Error upserting users", error));
                }
            });
        handleCallMenuClose();
    }

    const cannotCreateCall = Boolean(chatCall);
    const showCallButton = collectionName && ['chats'].includes(collectionName);
    const showManageUser = collectionName && user.userRoles.includes('ADMIN');

    return (
        <>
            <div style={styles.header}>
                <div style={styles.chatNameContainer}>
                    <Grow in key={chat?.name}>
                        <h2 style={styles.chatName}>{chat?.name}</h2>
                    </Grow>
                </div>

                {
                    showCallButton && (
                        <IconButton
                            id="call-menu"
                            aria-label="call"
                            aria-controls={callMenuOpen ? 'call-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={callMenuOpen ? 'true' : undefined}
                            onClick={(event) => setCallMenuAnchor(event.currentTarget)}
                        >
                            <VideoCallOutlined />
                        </IconButton>
                    )
                }
                <Menu
                    id="call-menu"
                    anchorEl={callMenuAnchor}
                    open={callMenuOpen}
                    onClose={handleCallMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'call-menu',
                    }}
                >
                    <MenuItem onClick={() => handleStartCallClick('video')} disabled={cannotCreateCall}>Start video call</MenuItem>
                    <MenuItem onClick={() => handleStartCallClick('audio')} disabled={cannotCreateCall}>Start audio call</MenuItem>
                </Menu>

                <IconButton aria-label="call" onClick={() => setMembersDialogOpen(true)}>
                    <PeopleOutline />
                </IconButton>

                <MembersManagmentDialog
                    disabled={!showManageUser}
                    collection={collectionName}
                    chat={chat}
                    open={membersDialogOpen}
                    onClose={() => setMembersDialogOpen(false)}
                    currentMembers={members}
                />

                <IconButton aria-label="manage-people">
                    <MoreVert />
                </IconButton>
            </div>
            <CallBar call={chatCall} />
        </>
    );
}

const styles = {
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '.5rem 1rem',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        gap: '1rem'
    },
    chatNameContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    chatName: {
        fontSize: '1rem',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '22rem'
    }
}