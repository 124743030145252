import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import 'react-chat-elements/dist/main.css';
import { Box, LinearProgress } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import _ from 'lodash';

import UserContext from '../contexts/UserContext';
import { db } from '../firebaseConfig';
import ConversationsList from '../components/chats-page/ConversationList';
import ChatRight from '../components/chats-page/ChatRight';


const ChatsPage = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [chats, setChats] = useState([]);
    const { id: paramChatId } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [currentChat, setCurrentChat] = useState(null);

    useEffect(() => {
        if (!user) return () => { };
        const unsubscribe = onSnapshot(
            query(collection(db, 'chats'), where("members", "array-contains", user.id)),
            (snapshot) => {
                console.log("Chat update ")
                const chats = [];
                snapshot.forEach((doc) => {
                    chats.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                const sortedChats = chats.sort((a, b) => b.lastUpdate - a.lastUpdate);
                setChats(sortedChats);
                if (chats.length > 0) {
                    setCurrentChat(sortedChats.find((chat) => chat.id === paramChatId) || sortedChats[0]);
                }
                setIsLoading(false);
            }
        );

        return () => unsubscribe();
    }, [paramChatId, user]);

    return (
        <>
            <Helmet>
                <title> {t('chats')}</title>
            </Helmet>
            {
                isLoading
                    ? <LinearProgress />
                    : !chats.length ? (
                        <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem">
                            <div style={{ fontSize: '2rem' }}>{t('No chat sessions')}</div>
                        </Box>
                    ) : (
                        <div style={styles.container} className='spacing-x-4'>

                            <div style={styles.flex}>
                                <div style={styles.conversationList}>
                                    <ConversationsList chats={chats} onClickChat={setCurrentChat} />
                                </div>
                                <div size={8} style={styles.messages}>
                                        <ChatRight
                                            collectionName='chats'
                                            chat={currentChat}
                                            user={user}
                                            t={t}
                                        />
                                </div>
                            </div>
                        </div>
                    )
            }
        </>
    );
}




const styles = {
    container: {
        height: '100%',
        position: 'relative'
    },
    flex: {
        height: 'calc(100vh - 116px)',
        width: '100%',
        position: 'absolute',
        display: 'flex',
    },
    messages: {
        position: 'relative',
        scrollbarWidth: 'none',
        paddingBottom: '2rem',
        height: '100%',
        flex: 2
    },
    conversationList: {
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        flex: 1
    }
}

export default ChatsPage;