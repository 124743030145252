import { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import UserContext from "../../contexts/UserContext";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], isCollapsed, ...other }) {
  const {user} = useContext(UserContext);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          const roles = user?.userRoles || [];
          const isAuthorized = !roles.some(role => item.unauthorized.includes(role));
          return ((roles.includes('ADMIN') || isAuthorized) && <NavItem key={item.title} item={item} displayLabel={!isCollapsed} />);
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, displayLabel }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          backgroundColor: 'lightblue',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      {displayLabel && <ListItemText disableTypography primary={title} />}
      {info && info}
    </StyledNavItem>
  );
}
