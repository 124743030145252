import { collection, getDocs, query, Timestamp, doc, updateDoc, setDoc, getDoc, where } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";
import { sendNewMessageNotifications } from "./notifications_service";


export async function getChatDetails(chatId) {
    const chatSnapshot = await getDoc(doc(db, 'chats', chatId));

    if (!chatSnapshot.exists) {
        return null;
    }

    return {
        id: chatId,
        ...chatSnapshot.data(),
    };
}

export async function getUserChats(userId) {
    const chatsSnapshot = await getDocs(query(
        collection(db, 'chats'),
        where("members", "array-contains", userId),
    ));

    const chatsPromise = Promise.all(chatsSnapshot.docs.map(async (snapshot) => ({
        id: snapshot.id,
        ...snapshot.data()
    }))).then((chats) => chats
        .filter(chat => chat !== null)
        .sort((a, b) => a.lastUpdate - b.lastUpdate));

    return chatsPromise;
}


export async function sendMessage(collection, message, chat, senderId, receivers) {
    const chatId = chat.id;
    const chatRef = doc(db, collection, chatId);
    const messageId = uuidv4();
    const text = message?.text || "";

    await updateDoc(chatRef, {
        lastMessage: text,
        lastUpdate: Timestamp.now()
    });

    await setDoc(doc(db, collection, chatId, 'messages', messageId), {
        createdAt: Timestamp.now(),
        message: text,
        image: message.image || null,
        senderId,
        id: messageId
    });

    sendNewMessageNotifications(chat, receivers, senderId);
}

export const sendChatSystemMessage = async (collection, chatId, text = "") => {
    const chatRef = doc(db, collection, chatId);
    const messageId = uuidv4();

    await updateDoc(chatRef, {
        lastMessage: text,
        lastUpdate: Timestamp.now()
    });

    await setDoc(doc(db, collection, chatId, 'messages', messageId), {
        createdAt: Timestamp.now(),
        message: text,
        image: null,
        senderId: 'system',
        id: messageId
    });
}