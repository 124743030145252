import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Container,
  Divider,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { City, Country, State } from 'country-state-city';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import FileUpload from '../components/file-upload';
import UserContext from '../contexts/UserContext';
import { updateAdmin } from '../services/admins_service';
import { uploadFile } from '../utils/fileUploader';
import QualificationSection from '../components/qualification-section';
import { formatImage } from '../utils/formatImage';
import { updateCoach } from '../services/coaches_service';
import Notification from '../components/notification';

export default function ProfilePage() {
  const { user, updateUserFromFirestore } = useContext(UserContext);
  const { t } = useTranslation();
  const [image, setImage] = useState();
  const [payload, setPayload] = useState({
    name: user?.name || '',
    country: user?.country || '',
    city: user?.city || '',
    profession: user?.profession || '',
    phoneNumber: user?.phoneNumber || '',
    birthdate: user?.birthdate ? dayjs(user?.birthdate) : null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    success: false,
    message: '',
  });

  useEffect(() => {
    setPayload({
      name: user?.name || '',
      country: user?.country || '',
      city: user?.city || '',
      state: user?.state || '',
      profession: user?.profession || '',
      phoneNumber: user?.phoneNumber || '',
      birthdate: user?.birthdate ? dayjs(user?.birthdate) : null,
    });
  }, [user]);

  const notify = (isSuccess) => {
    setNotification({
      open: true,
      success: isSuccess,
      message: isSuccess ? t('Profile updated successfully') : t('Profile update failed'),
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    payload.updated_at = Timestamp.now();
    setError(null);

    if (!payload.name) {
      setError(t('Enter your name'));
      return;
    }

    setIsLoading(true);

    if (payload.birthdate) {
      payload.birthdate = payload.birthdate.format('MM.DD.YYYY');
    }

    if (image) {
      payload.image = await uploadFile(image);
    }

    try {
      if (user?.userRoles.includes('ADMIN')) {
        const data = await updateAdmin(user.id, payload);
        updateUserFromFirestore(data);
        notify(true);
      } else {
        const data = await updateCoach(user.id, payload);
        updateUserFromFirestore(data);
        notify(true);
      }
    } catch (error) {
      setError(error.message);
      notify(false);
    } finally {
      setIsLoading(false);
    }
  }

  const onChangeDate = (value) => {
    setPayload((prevFormData) => ({
      ...prevFormData,
      birthdate: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayload((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);

  useEffect(() => {
    if (payload.country) {
      const stateData = State.getStatesOfCountry(payload.country);
      setStates(stateData);
    } else {
      setStates([]);
    }
    setCities([]);
  }, [payload.country]);

  useEffect(() => {
    if (payload.state) {
      const cityData = City.getCitiesOfState(payload.country, payload.state);
      setCities(cityData);
    } else {
      setCities([]);
    }
  }, [payload.country, payload.state]);

  return (
    <>
      <Helmet>
        <title>{t('Profile Settings')}</title>
      </Helmet>

      <Container>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Profile Settings')}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="flex-start"
            alignItems={{ xs: 'center', md: 'flex-start' }}
            divider={<Divider sx={{ display: { xs: 'none', md: 'block' } }} orientation="vertical" flexItem />}
            spacing={3}
          >
            <Stack justifyContent="flex-start" width={{ xs: '100%', md: '50%' }} spacing={2} flex={3}>
              <Stack justifyContent="flex-start" alignItems="center" spacing={2}>
                <Stack position="relative">
                  <Avatar
                    src={formatImage(image?.preview || user?.image, { w: 256, h: 256 })}
                    sx={{ width: 128, height: 128 }}
                    alt="photoURL"
                  />
                  {
                    image?.preview &&
                      <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', borderRadius: 999, overflow: 'hidden' }}>
                        <Typography sx={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0,
                          color: 'white',
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          padding: 1,
                          borderRadius: 1,
                          width: '100%',
                          textAlign: 'center',
                        }}>{t("Preview")}</Typography>
                      </div>
                  }
                </Stack>
                <Typography>{user?.name}</Typography>
                <Typography>{user?.email}</Typography>
                <FileUpload maxSize={10} onChange={setImage} preview={false} edit />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h5" gutterBottom>
                  {t('Qualifications')}
                </Typography>

                <QualificationSection id={user.id} />
              </Stack>
            </Stack>
            <Stack spacing={2} width={{ xs: '100%', md: '50%' }} flex={1}>
              <InputLabel id="name-label">{t('Name')} </InputLabel>
              <TextField name="name" value={payload.name} onChange={handleChange} />

              <InputLabel id="country-label">{t('Country')}</InputLabel>
              <TextField name={'country'} select value={payload.country} onChange={handleChange}>
                <MenuItem value="" disabled>
                  {t('Select Country')}
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.isoCode} value={country.isoCode}>
                    {t(country.name)}
                  </MenuItem>
                ))}
              </TextField>

              <InputLabel id="state-label">{t('State')}</InputLabel>
              <TextField
                key={payload.country + states.length}
                select
                value={payload.state}
                name={'state'}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  {t('Select State')}
                </MenuItem>
                {states.map((state) => (
                  <MenuItem key={state.isoCode} value={state.isoCode}>
                    {t(state.name)}
                  </MenuItem>
                ))}
              </TextField>

              <InputLabel id="city-label">{t('City')}</InputLabel>
              <TextField
                key={payload.state + cities.length}
                select
                value={payload.city}
                name={'city'}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  {t('Select City')}
                </MenuItem>
                {(cities || []).length ? (
                  cities.map((city) => (
                    <MenuItem key={city.name} value={city.name}>
                      {t(city.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No cities available
                  </MenuItem>
                )}
              </TextField>

              <InputLabel id="profession-label">{t('Profession')}</InputLabel>
              <TextField name="profession" value={payload.profession} onChange={handleChange} />

              <InputLabel id="birthdate-label">{t('Birthdate')}</InputLabel>
              <DatePicker value={payload.birthdate} onChange={onChangeDate} format={'DD.MM.YYYY'} />

              <InputLabel id="phone-number-label">{t('Phone Number')}</InputLabel>
              <TextField
                name="phoneNumber"
                value={payload.phoneNumber}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="tel"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Box pt={5}>
                {error && <Alert severity="error">{error}</Alert>}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('Update')}
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </form>
      </Container>
      <Notification {...notification} onClose={() => setNotification({ ...notification, open: false })} />
    </>
  );
}
