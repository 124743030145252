import { useEffect, useState } from "react";
import { Alert, Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebaseConfig";

function ResetPasswordPage() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const onSubmit = ({ email }) => {
        setError(null);
        setIsLoading(true);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setIsSent(true);
                console.log("Password reset email sent!");
            })
            .catch((error) => {
                setError(error.message);
                console.error("Error sending password reset email:", error.code, error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleTimerFinish = () => {
        setIsSent(false);
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" sx={{ backgroundColor: "#F7FAFC" }}>
            <Box
                display="flex" flexDirection="column" gap={3}
                width="100%" maxWidth="24rem" padding={4} borderRadius={1}
                sx={{ backgroundColor: "#FFFFFF", boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)" }}
            >
                <Box display="flex" flexDirection="column" gap={1} alignItems="center">
                    <img src="assets/logo_with_name.png" alt="logo" style={{ maxWidth: "6rem", marginBottom: "1rem" }} />
                    <Typography variant="h5" fontWeight={600} textAlign="center">
                        Сброс пароля
                    </Typography>
                    <Typography variant="body2" color="text.secondary" textAlign="center">
                        Введите адрес электронной почты, чтобы сбросить пароль
                    </Typography>

                </Box>
                {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
                {
                    isSent &&
                    <Box>
                        <Typography variant="body2" color="text.secondary" textAlign="center" sx={{ marginBottom: 1 }}>
                            Время до очередного запроса: <Timer initialTime={60} onFinish={handleTimerFinish} />
                        </Typography>
                        <Alert severity="success">Пароль успешно сброшен</Alert>
                    </Box>
                }

                <ResetPasswordForm loading={isLoading} disabled={isSent} onFinish={onSubmit} />

                <Box textAlign="center">
                    <p>Не помните свой пароль? <a href="/login">Войти</a></p>
                </Box>
            </Box>
        </Box>
    );
}

ResetPasswordForm.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onFinish: PropTypes.func.isRequired,
};
function ResetPasswordForm({ loading, disabled, onFinish }) {
    const [error, setError] = useState(null);
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            setError(null);
            const formData = new FormData(e.currentTarget);
            const email = formData.get("email");
            if (!email) {
                setError("Введите адрес электронной почты");
                return;
            }
            onFinish({ email });
        }}>
            <TextField
                disabled={disabled || loading}
                id="email"
                name="email"
                label="Пароль"
                variant="standard"
                fullWidth
                error={!!error}
                helperText={error}
            />
            <LoadingButton fullWidth sx={{ marginTop: 4 }} variant="contained" type="submit" loading={loading} disabled={disabled}>
                Отправить
            </LoadingButton>
        </form>
    );
}

Timer.propTypes = {
    initialTime: PropTypes.number.isRequired,
    onFinish: PropTypes.func.isRequired,
};
function Timer({ initialTime, onFinish }) {
    const [seconds, setSeconds] = useState(initialTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prev) => {
                if (prev === 0) {
                    clearInterval(interval);
                    onFinish();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return <span>{formatTime(seconds)}</span>;
};

export default ResetPasswordPage;