import { bool, func, array, object, string } from "prop-types";
import { useState, useEffect } from "react";
import _ from 'lodash';
import { doc, updateDoc } from "firebase/firestore";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Typography } from "@mui/material";
import { Delete } from "@material-ui/icons";
import { db } from "../../firebaseConfig";
import UserSearch from "../user-search";
import { sendChatAddedNotification } from "../../services/notifications_service";
import { sendChatSystemMessage } from "../../services/chats_service";

MembersManagmentDialog.propTypes = {
    disabled: bool,
    collection: string,
    chat: object,
    open: bool,
    onClose: func,
    currentMembers: array
};
export default function MembersManagmentDialog({ disabled, collection, chat, currentMembers, open, onClose }) {
    const [members, setMembers] = useState(currentMembers);
    const [isLoading, setIsLoading] = useState(false);
    const [snackData, setSnackData] = useState({ open: false, message: "" });
    const chatId = chat?.id;

    const handleAddMember = (newMember) => {
        const m = members.find(_m => _m.name === newMember.name && _m.role === newMember.role);
        if (!m) {
            setMembers([...members, newMember]);
        }
    };

    const removeMember = (memberId) => {
        setMembers(members.filter(m => m.id !== memberId));
    };

    const handleCancel = () => {
        setMembers(currentMembers);
        onClose();
    };

    const handleSave = () => {
        setIsLoading(true);
        const chatRef = doc(db, 'chats', chatId);
        updateDoc(chatRef, {
            members: members.map((m) => m.id)
        })
            .then(() => {
                setSnackData({ open: true, message: "Members updated" });
                onClose();

                const removedMembers = _.differenceBy(currentMembers, members, 'id');
                const addedMembers = _.differenceBy(members, currentMembers, 'id');
                if (removedMembers.length > 0) {
                    sendChatSystemMessage(collection, chatId, `${removedMembers.map((m) => m.name).join(', ')} removed from the chat`);
                }
                if (addedMembers.length > 0) {
                    sendChatAddedNotification(chat, addedMembers);
                    sendChatSystemMessage(collection, chatId, `${addedMembers.map((m) => m.name).join(', ')} added to the chat`);
                }
            })
            .catch((error) => {
                console.error("Error updating members", error);
                setSnackData({ open: true, message: "Error updating members" });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setMembers(currentMembers);
    }, [currentMembers]);

    const selectedIds = members.map((m) => m.id);
    const noUpdate = !_.isEqual(members, currentMembers);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
        >
            <Snackbar
                open={snackData.open}
                autoHideDuration={5000}
                onClose={() => setSnackData({ open: false, message: "" })}
                message={snackData.message}
            />
            <DialogTitle>Manage members</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add or remove members from the chat
                </DialogContentText>
                <UserSearch disabled={disabled} size="small" style={{ marginTop: '.5rem' }} selectedUserIds={selectedIds} onOptionSelect={handleAddMember} />
                <Box marginTop="1rem">
                    <Typography variant="subtitle2">Members</Typography>
                    <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
                        <List>
                            {
                                members.map((member) => (
                                    <ListItem key={member.id} secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => removeMember(member.id)} disabled={isLoading || disabled}>
                                            <Delete />
                                        </IconButton>
                                    }>
                                        <ListItemAvatar>
                                            <Avatar src={member.image} alt={member.name} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={member.name}
                                            secondary={member.role}
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={handleCancel}>Cancel</Button>
                <Button disabled={!noUpdate || isLoading} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}