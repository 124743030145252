import React, { createContext, useState, useEffect } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { LinearProgress } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig";

const UserContext = createContext({});

const publicPaths = ["/login", "/register", "/reset-password", "/legals", "/404"];

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchUserRoles = async (userId) => {
        try {
            const adminRef = doc(db, "admins", userId);
            const coachRef = doc(db, "coaches", userId);

            const [adminDoc, coachDoc] = await Promise.all([getDoc(adminRef), getDoc(coachRef)]);

            const userRoles = [];
            let newUserData = {};

            if (adminDoc.exists()) userRoles.push("ADMIN");
            if (coachDoc.exists()) {
                userRoles.push("COACH");
                newUserData = coachDoc.data();
            }

            return userRoles.length > 0 ? { ...newUserData, userRoles } : null;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return null;
        }
    };

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                setUserData(null);
                setIsLoading(false);
                return;
            }

            const userData = await fetchUserRoles(user.uid);
            setUserData(userData);
            setIsLoading(false);

            // Realtime updates from Firestore
            const adminRef = doc(db, "admins", user.uid);
            const coachRef = doc(db, "coaches", user.uid);

            const unsubscribeAdmin = onSnapshot(adminRef, (adminSnapshot) => {
                setUserData((prevUserData) => {
                    const roles = prevUserData?.userRoles || [];
                    return adminSnapshot.exists() && !roles.includes("ADMIN")
                        ? { ...prevUserData, userRoles: [...roles, "ADMIN"] }
                        : prevUserData;
                });
            });

            const unsubscribeCoach = onSnapshot(coachRef, (coachSnapshot) => {
                setUserData((prevUserData) => {
                    const roles = prevUserData?.userRoles || [];
                    return coachSnapshot.exists()
                        ? { ...prevUserData, ...coachSnapshot.data(), userRoles: roles.includes("COACH") ? roles : [...roles, "COACH"] }
                        : prevUserData;
                });
            });

            const unsubscribe = () => {
                unsubscribeAdmin();
                unsubscribeCoach();
            };

            // eslint-disable-next-line consistent-return
            return unsubscribe;
        });

        return () => unsubscribeAuth();
    }, []);

    useEffect(() => {
        if (isLoading) return;

        if (userData) {
            if (location.pathname === "/login") {
                navigate("/dashboard");
            }
        } else {
            const isPublicPath = publicPaths.some((path) => location.pathname.startsWith(path));
            if (!isPublicPath) {
                navigate("/login");
            }
        }
    }, [userData, location.pathname, isLoading, navigate]);

    return isLoading ? (
        <LinearProgress />
    ) : (
        <UserContext.Provider value={{ user: userData, isLoading }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
