import { createContext, useContext, useEffect, useState } from 'react';
import { StreamCall, StreamVideo, StreamVideoClient } from '@stream-io/video-react-sdk';
import PropTypes from 'prop-types';
import UserContext from './UserContext';
import { fetchGetStreamToken, upsertUsers } from '../services/call_service';
import CallLayer from '../components/call-layer';
import CallNotificationProvider from '../components/call-notification-provider';


const API_KEY = '4ty9gyv4z5ev';
const AVCallContext = createContext({});

const inSound = new Audio('/assets/sounds/ringtone.mp3');
const outSound = new Audio('/assets/sounds/outgoing.mp3');

export function AVCallProvider({ children }) {
  const { user } = useContext(UserContext);
  const [client, setClient] = useState();
  const [currentCall, setCurrentCall] = useState();
  const [callingSound, setCallingSound] = useState(null);

  const playSound = (forIncoming) => {
    const sound = forIncoming ? inSound : outSound;
    sound.loop = true;
    sound.play();
    setCallingSound(sound);
  };

  const stopSound = () => {
    if (callingSound) {
      callingSound.pause();
      setCallingSound(null);
    }
  };

  const initializeClient = () => {
    if (!user) {
      return null;
    }
    const _user = {
      id: user.id,
      name: user.name,
      image: user.image,
    };
    const tokenProvider = async () => fetchGetStreamToken(_user.id);
    const myClient = new StreamVideoClient({
      apiKey: API_KEY,
      user: _user,
      tokenProvider,
    });
    setClient(myClient);

    return myClient;
  };

  useEffect(() => {
    if (user) {
      const _user = {
        id: user.id,
        name: user.name,
        image: user.image,
      };

      upsertUsers([_user])
        .then(() => {
          console.log('AVCallProvider | User upserted');
        })
        .catch((error) => {
          console.error('Error upserting user', error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (user){
      const myClient = initializeClient();
      setClient(myClient);

      return () => {
        if (myClient) {
          myClient.disconnectUser();
          setClient(undefined);
        }
      };
    }
    return () => {};
  }, [user]);

  console.log('AVCallProvider | Current Call :', currentCall);

  return (
    <AVCallContext.Provider
      value={{ client, currentCall, setCurrentCall, playSound, stopSound, isPlayingSound: callingSound !== null }}>
      <StreamVideo client={client}>
        <StreamCall call={currentCall}>
          <CallLayer />
          <CallNotificationProvider>
            {children}
          </CallNotificationProvider>
        </StreamCall>
      </StreamVideo>
    </AVCallContext.Provider>
  );
}

AVCallProvider.propTypes = { children: PropTypes.node.isRequired };

export default AVCallContext;