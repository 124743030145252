import React from "react";
import { Container, Typography, Box, Link, Divider } from "@mui/material";
import { useParams } from "react-router-dom";


    
const English = () => (
    <Container maxWidth="md" sx={{ py: 8, }}>
        <Typography variant="h4" component="h1" gutterBottom>
            Terms of Use for Guided Path
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Effective Date: 25/11/2024
        </Typography>
        <Typography variant="body1" paragraph>
            Welcome to Guided Path! By accessing or using our platform, you agree to abide by these
            Terms of Use. If you do not agree, you may not use our services. These Terms govern your
            access to and use of Guided Path, operated by BOAD OU.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                1. General Information
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path connects users with therapists, coaches, and other professionals for online
                and offline consultations. The platform is open to users aged 18 and older. Young adults
                below 18 may use the platform with parental supervision.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                2. Account Creation
            </Typography>
            <Typography paragraph>
                To access Guided Path, you must create an account by providing a valid email address,
                phone number, and password.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Account Rules:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Accounts are personal and may not be shared or transferred.</li>
                <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                <li>You must notify us immediately of any unauthorized use of your account.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                3. Service Use
            </Typography>
            <Typography variant="body1" paragraph>
                By using Guided Path, you agree to:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Use the platform solely for its intended purpose.</li>
                <li>Refrain from contacting coaches or other service providers off-platform.</li>
                <li>Avoid the use of abusive or foul language in communications.</li>
                <li>Respect the rights of other users and service providers.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Session Cancellation and Refunds:
            </Typography>
            Users may cancel sessions and request refunds under the following conditions:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Cancellations must be made at least 24 hours before the scheduled session to qualify for a full refund.</li>
                <li>Sessions canceled less than 24 hours in advance may incur a cancellation fee.</li>
                <li>Refunds are subject to review and approval.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                4. Payments
            </Typography>
            <Typography variant="body1" paragraph>
                Payments are processed by a third-party payment platform. By using Guided Path, you agree
                to their terms and policies, which govern payment processing, refunds, and chargebacks.
            </Typography>
            <Typography variant="body1" paragraph>
                We offer both subscription plans and pay-as-you-go options for accessing services.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                5. Content
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path includes a chat function and allows users to rate service providers. By
                submitting content, you agree to the following rules:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Content must not include offensive, defamatory, or illegal material.</li>
                <li>Reviews must reflect genuine experiences and not be influenced by incentives.</li>
                <li>Guided Path reserves the right to moderate, edit, or remove user-generated content.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Ownership:
            </Typography>
            <Typography variant="body1" paragraph>
                All intellectual property, including but not limited to the platform’s design, logos, and
                content, is owned by BOAD OU.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                6. Liability
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Disclaimer:
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path provides a platform to connect users with professionals. We do not endorse,
                guarantee, or assume responsibility for the advice or services provided by professionals
                on the platform. All interactions and outcomes are solely between the user and the professional.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Limitation of Liability:
            </Typography>
            To the fullest extent permitted by law:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Guided Path is not liable for any indirect, incidental, special, or consequential damages resulting from the use of the platform, including but not limited to service interruptions or data breaches.</li>
                <li>Our total liability for any claim arising out of or relating to the platform is limited to the amount you paid to use the service in the 12 months preceding the claim. </li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                7. Termination
            </Typography>
            <Typography paragraph>
                We may terminate or suspend your account for:
            </Typography>
            <ul style={{ marginLeft: '2rem', paddingBottom: "1rem" }}>
                <li>Violation of these Terms of Use.</li>
                <li>Misuse of services or inappropriate behavior.</li>
                <li>Requests by you to deactivate or delete your account.</li>
            </ul>
            <Typography paragraph>
                Users can deactivate or delete their accounts by contacting <a href="mailto:support@guidedpath.com">support@guidedpath.com </a>.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                8. Governing Law
            </Typography>
            <Typography paragraph>
                These Terms are governed by the laws of the Russian Federation. Any disputes arising from these Terms will be resolved under the jurisdiction of the courts in the Russian Federation.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                9. Contact Information
            </Typography>
            <Typography variant="body1" paragraph>
                For questions or concerns regarding these Terms, please contact us:
            </Typography>
            <Typography variant="body1">
                Email:{" "}
                <Link href="mailto:support@guidedpath.com" color="primary">
                    support@guidedpath.com
                </Link>
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography paragraph>
                By using Guided Path, you acknowledge and agree to these Terms of Use. We reserve the right to update or modify these Terms at any time. Continued use of the platform constitutes acceptance of the revised Terms.
            </Typography>
        </Box>
    </Container>
);
    
const Russian = () => (
    <Container maxWidth="md" sx={{ py: 8, }}>
        <Typography variant="h4" component="h1" gutterBottom>
            Условия использования Guided Path
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Дата вступления в силу: 25.11.2024
        </Typography>
        <Typography variant="body1" paragraph>
            Добро пожаловать в Guided Path! Получая доступ или используя нашу платформу, вы соглашаетесь соблюдать настоящие Условия использования. Если вы не согласны, вы не можете использовать наши услуги. Настоящие Условия регулируют ваш доступ и использование платформы Guided Path, управляемой BOAD OU.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                1. Общая информация
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path соединяет пользователей с терапевтами, коучами и другими профессионалами для онлайн- и оффлайн-консультаций. Платформа доступна для пользователей в возрасте 18 лет и старше. Молодые люди младше 18 лет могут использовать платформу под присмотром родителей.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                2. Создание аккаунта
            </Typography>
            <Typography paragraph>
                Для доступа к Guided Path вы должны создать аккаунт, указав действительный адрес электронной почты, номер телефона и пароль.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Правила аккаунта:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Аккаунты являются личными и не могут быть переданы или использованы совместно.</li>
                <li>Вы несете ответственность за сохранение конфиденциальности данных вашего аккаунта.</li>
                <li>Вы должны немедленно уведомить нас о любом несанкционированном использовании вашего аккаунта.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                3. Использование услуг
            </Typography>
            <Typography variant="body1" paragraph>
                Используя Guided Path, вы соглашаетесь:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Использовать платформу исключительно в соответствии с ее предназначением.</li>
                <li>Воздерживаться от связи с коучами или другими поставщиками услуг вне платформы.</li>
                <li>Избегать использования оскорбительных или ненормативных выражений в общении.</li>
                <li>Уважать права других пользователей и поставщиков услуг.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Отмена сессий и возврат средств:
            </Typography>
            Пользователи могут отменять сессии и запрашивать возврат средств при следующих условиях:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Отмена должна быть произведена не менее чем за 24 часа до запланированной сессии для получения полного возврата.</li>
                <li>Сессии, отмененные менее чем за 24 часа, могут облагаться штрафом за отмену.</li>
                <li>Возврат средств подлежит рассмотрению и утверждению.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                4. Платежи
            </Typography>
            <Typography variant="body1" paragraph>
                Платежи обрабатываются сторонней платежной платформой. Используя Guided Path, вы соглашаетесь с их условиями и политиками, которые регулируют обработку платежей, возврат средств и оспаривание транзакций.
            </Typography>
            <Typography variant="body1" paragraph>
                Мы предлагаем как подписки, так и оплату по факту использования услуг.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                5. Контент
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path включает функцию чата и позволяет пользователям оценивать поставщиков услуг. Отправляя контент, вы соглашаетесь со следующими правилами:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Контент не должен содержать оскорбительных, клеветнических или незаконных материалов.</li>
                <li>Отзывы должны отражать реальный опыт и не быть подвержены влиянию стимулов.</li>
                <li>Guided Path оставляет за собой право модерировать, редактировать или удалять пользовательский контент.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Права собственности:
            </Typography>
            <Typography variant="body1" paragraph>
                Вся интеллектуальная собственность, включая, но не ограничиваясь дизайном платформы, логотипами и контентом, принадлежит BOAD OU.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                6. Ответственность
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Отказ от ответственности:
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path предоставляет платформу для связи пользователей с профессионалами. Мы не поддерживаем, не гарантируем и не несем ответственности за советы или услуги, предоставляемые профессионалами на платформе. Все взаимодействия и результаты являются исключительно ответственностью пользователя и профессионала.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Ограничение ответственности:
            </Typography>
            В максимальной степени, разрешенной законом:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Guided Path не несет ответственности за любые косвенные, случайные, специальные или косвенные убытки, возникшие в результате использования платформы, включая, но не ограничиваясь перерывами в обслуживании или утечками данных.</li>
                <li>Наша общая ответственность по любому иску, связанному с платформой, ограничивается суммой, которую вы заплатили за использование услуг за 12 месяцев, предшествующих иску.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                7. Прекращение действия
            </Typography>
            <Typography paragraph>
                Мы можем прекратить или приостановить действие вашего аккаунта за:
            </Typography>
            <ul style={{ marginLeft: '2rem', paddingBottom: "1rem" }}>
                <li>Нарушение настоящих Условий использования.</li>
                <li>Неправильное использование услуг или недопустимое поведение.</li>
                <li>Запросы с вашей стороны на деактивацию или удаление аккаунта.</li>
            </ul>
            <Typography paragraph>
                Пользователи могут деактивировать или удалить свои аккаунты, связавшись с <a href="mailto:support@guidedpath.com">support@guidedpath.com</a>.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                8. Применимое право
            </Typography>
            <Typography paragraph>
                Настоящие Условия регулируются законодательством Российской Федерации. Любые споры, возникающие из этих Условий, будут разрешаться в судах Российской Федерации.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                9. Контактная информация
            </Typography>
            <Typography variant="body1" paragraph>
                По вопросам или замечаниям, связанным с настоящими Условиями, пожалуйста, свяжитесь с нами:
            </Typography>
            <Typography variant="body1">
                Электронная почта:{" "}
                <Link href="mailto:support@guidedpath.com" color="primary">
                    support@guidedpath.com
                </Link>
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography paragraph>
                Используя Guided Path, вы подтверждаете и соглашаетесь с настоящими Условиями использования. Мы оставляем за собой право обновлять или изменять эти Условия в любое время. Продолжение использования платформы означает принятие измененных Условий.
            </Typography>
        </Box>
    </Container>
);

const TermsOfUse = () => {
    const { lng } = useParams();

    if (lng === 'en') return <English />;
    return <Russian />;
};

export default TermsOfUse;
