import { array, func } from "prop-types";
import { ChatList } from "react-chat-elements";
import { formatImage } from "../../utils/formatImage";

ConversationsList.propTypes = {
    chats: array,
    onClickChat: func
};
export default function ConversationsList({ chats, onClickChat }) {
    const toRender = chats.map((chat) => ({
        ...chat,
        avatar: formatImage(chat.image, { w: 72, h: 72 }),
        alt: chat.name,
        title: chat.name,
        subtitle: chat.lastMessage,
        date: chat.lastUpdate ? new Date(chat.lastUpdate.toMillis()) : new Date(),
    }));

    return (
        <div style={styles.chatListContainer}>
            <div style={{ flex: 1, width: '100%', height: '100%' }}>
                <ChatList className="chat-list" style={styles.chatList} dataSource={toRender} onClick={onClickChat} />
            </div>
        </div>
    );
}

const styles = {
    chatListContainer: {
        display: 'flex',
        alignItems: 'row',
        position: 'absolute',
        width: '100%',
        height: '100%',
        marginRight: 8,
        backgroundColor: '#ededed',
    },
    chatList: {
        height: '100M'
    }
}