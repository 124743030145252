import { useState, useEffect, useRef } from 'react';
import {
    Autocomplete,
    TextField,
    InputAdornment,
    IconButton,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Typography,
    ListItemButton,
    debounce,
    CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import { getDocs, query, collection, where, limit } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { formatImage } from '../../utils/formatImage';


UserSearch.propTypes = {
    label: PropTypes.string,
    selectedUserIds: PropTypes.array,
    onOptionSelect: PropTypes.func
};
function UserSearch({ label = "Search users", selectedUserIds = [], onOptionSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const [defaultOptions, setDefaultOptions] = useState([]);

    const fetchUsers = async (searchTerm) => {
        if (!searchTerm) return;

        setLoading(true);
        const roles = ['admins', 'users', 'coaches'];

        try {
            const queryPromises = roles.map((role) => {
                const roleCollection = collection(db, role);
                const q = query(
                    roleCollection,
                    where('name', '>=', searchTerm),
                    where('name', '<=', `${searchTerm}\uf8ff`),
                    limit(10)
                );
                return getDocs(q);
            });

            const querySnapshots = await Promise.all(queryPromises);

            const fetchedUsers = [];
            querySnapshots.forEach((querySnapshot, index) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    fetchedUsers.push({
                        id: doc.id,
                        name: data.name,
                        image: data.image ? formatImage(data.image, { w: 72, h: 72 }) : '',
                        role: roles[index]
                    });
                });
            });
            const externalUsers = fetchedUsers.filter((user) => !selectedUserIds.includes(user.id));
            setOptions(externalUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = debounce((searchTerm) => {
        fetchUsers(searchTerm);
    }, 300);

    useEffect(() => {
        setPreloading(true);

        (async () => {
            const coachesSnap = await getDocs(collection(db, 'coaches'));
            const coaches = coachesSnap.docs.map((doc) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    name: data.name,
                    image: data.image ? formatImage(data.image, { w: 72, h: 72 }) : '',
                    role: 'coaches'
                };
            });
            const externalCoaches = coaches.filter((coach) => !selectedUserIds.includes(coach.id));
            return externalCoaches;
        })()
            .then((data) => {
                setDefaultOptions(data);
                setOptions(data);
            })
            .catch((error) => console.error('--Error fetching coaches:', error))
            .finally(() => setPreloading(false));
    }, []);

    useEffect(() => {
        if (!inputValue) {
            setOptions(defaultOptions);
        }
        handleSearch(inputValue);
    }, [inputValue]);

    return (
        <Autocomplete
            id="user-search"
            sx={{ width: 300 }}
            options={options}
            getOptionLabel={(option) => option.name}
            loading={preloading || loading}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderOption={(props, option) => (
                <ListItemButton {...props} key={`${option.id}_${option.role}`} onClick={() => onOptionSelect(option)}>
                    <ListItemAvatar>
                        <Avatar src={option.image} alt={option.name} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={option.name}
                        secondary={`Group: ${option.role}`}
                    />
                </ListItemButton>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            {...props}
        />
    );
};


export default UserSearch;