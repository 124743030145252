import { AttachFile } from "@material-ui/icons";
import { Box, Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { MessageList } from "react-chat-elements";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { sendMessage } from "../../services/chats_service";
import { getUserMinInfos } from "../../services/users_service";
import { uploadFile } from "../../utils/fileUploader";
import FileConfirmationModal from '../file-confirmation-modal';
import ChatRightHeader from "./ChatRightHeader";
import ImageDisplayModal from '../image-display-modal';

const ChatRight = memo(ChatRightComponent, (prevProps, nextProps) => {
    const sameChat = prevProps.chat?.id === nextProps.chat?.id;
    const sameMembers = _.isEqual(prevProps.chat?.members, nextProps.chat?.members);
    const sameCall = _.isEqual(prevProps.chat?.callInfos, nextProps.chat?.callInfos);
    return sameChat && sameMembers && sameCall;
});
ChatRightComponent.propTypes = {
    collectionName: PropTypes.string,
    chat: PropTypes.object,
    user: PropTypes.object,
    t: PropTypes.func
};
function ChatRightComponent({ collectionName = 'chats', chat, t, user }) {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [inputMessage, setInputMessage] = useState('');
    const { id: chatId } = chat;

    const [imageToDisplay, setImageToDisplay] = useState(null);

    const handleTitleClick = (message) => {
        navigate(`/users/${message.senderId}`);
    };

    const sortByCreatedAt = (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis();

    const processMessagesToRender = useCallback((messages) => messages
        .map((message) => ({
            ...message,
            position: message.senderId === user?.id ? 'right' : 'left',
            type: message.image ? 'photo' : message.senderId === 'system' ? 'system' : 'text',
            title: message.senderName,
            text: message?.message,
            date: new Date(message.createdAt.toMillis()),
            status: "received",
            ...(message.image ? {
                data: {
                    uri: message.image,
                    status: {
                        click: false,
                        download: true
                    },
                },
            } : {})
        }))
        .sort(sortByCreatedAt), [user]);

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        if (inputMessage.trim() !== '') {
            try {
                setMessages([...messages, {
                    position: 'right',
                    type: 'text',
                    title: user.name,
                    text: inputMessage,
                    date: new Date(),
                    status: 'waiting'
                }]);

                sendMessage(collectionName, { text: inputMessage }, chat, user.id, members);
                setInputMessage('');
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    }

    const handleSendImage = (file) => {
        try {
            const reader = new FileReader();

            reader.onloadend = () => {
                const uri = reader.result;

                setMessages([...messages, {
                    position: 'right',
                    type: 'photo',
                    title: user.name,
                    data: { uri, status: { download: true } },
                    date: new Date(),
                    status: 'waiting'
                }]);

                uploadFile(file)
                    .then(url => sendMessage(collectionName, { image: url }, chat, user.id, members));
            };

            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error sending image:', error);
        }
    };

    useEffect(() => {
        if (!chat?.members) return () => { };

        const fetchMembers = async () => {
            setIsLoading(true);
            console.log("Fetching chat members informations");
            const membersId = chat.members;
            const members = await Promise.all(membersId.map(getUserMinInfos));
            setMembers(members);
            setIsLoading(false);
        };

        fetchMembers();
        return () => { };
    }, [chat]);

    useEffect(() => {
        if (!chatId || !members.length) return () => { };

        const handleSnapshot = (querySnapshot) => {
            setIsLoading(true);
            const messages = querySnapshot.docs.map((doc) => {
                const member = members.find((m) => m.id === doc.data().senderId);
                return {
                    ...doc.data(),
                    id: doc.id,
                    senderName: member?.name || 'None',
                };
            });
            const processedMessages = processMessagesToRender(messages);
            setMessages(processedMessages);
            setIsLoading(false);
        }

        const unsubscribe = onSnapshot(collection(db, collectionName, chatId, 'messages'), handleSnapshot);

        return () => unsubscribe();
    }, [chatId, members, processMessagesToRender]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ChatRightHeader chat={chat} members={members} collectionName={collectionName} />
            <div style={styles.messageListContainer}>
                {
                    isLoading ?
                        <Box display='flex' height='100%' justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </Box>
                        : <MessageList
                            className="message-list"
                            lockable
                            toBottomHeight={'100%'}
                            dataSource={messages}
                            onTitleClick={handleTitleClick}
                            onOpen={({ image }) => {
                                setImageToDisplay(image);
                            }}
                        />
                }
            </div>

            <div style={{ display: 'flex', marginTop: '8px', padding: '0 1rem', gap: 8 }}>
                <ImageSender sx={{ marginLeft: '8px' }} onSendConfirm={handleSendImage} />

                <TextField
                    sx={{ flex: 1 }}
                    autofocus
                    multiline
                    placeholder={t('Type here...')}
                    id="send-message"
                    label={t('Send message')}
                    variant="outlined"
                    value={inputMessage}
                    onChange={(event) => setInputMessage(event?.target?.value)}
                    onKeyDown={handleKeyDown}
                />

                <Button variant="contained" onClick={handleSend} disabled={isLoading}>
                    {t('Send')}
                </Button>
            </div>
            <ImageDisplayModal url={imageToDisplay} onClose={() => setImageToDisplay(null)} />
        </div>
    );
}

ImageSender.propTypes = {
    onSendConfirm: PropTypes.func,
    sx: PropTypes.object
}
function ImageSender({ onSendConfirm, sx }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleClickAttach = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file)
        }
    }

    const handleSendConfirmation = () => {
        if (selectedFile) {
            onSendConfirm(selectedFile);
            setSelectedFile(null);
        }
    }

    return (
        <IconButton aria-label="upload file" size="large" component="label" sx={sx}>
            <AttachFile fontSize="inherit" />
            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleClickAttach}
            />
            <FileConfirmationModal file={selectedFile} onSend={handleSendConfirmation} onClose={() => setSelectedFile(null)} />
        </IconButton>
    )
}

const styles = {
    messageListContainer: {
        flex: 1,
        overflow: 'auto',
        backgroundColor: '#f5f5f5',
    }
}

export default ChatRight;