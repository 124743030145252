import { useEffect, useState } from 'react';
import { collection, getCountFromServer, getDocs, doc, deleteDoc, query, where, Timestamp, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useFetchItemDailyCount } from './item_daily_count_fetch_service';
import toProperCase from '../utils/toProperCase';
import { formatImage } from '../utils/formatImage';

export const useFetchAllUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const reloadUsers = () => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'users';

      const usersRef = collection(db, collectionName);
      const querySnapshot = await getDocs(usersRef);
      const users = querySnapshot.docs.map((doc) => doc.data());
      setUsers(
        users.map((user) => ({
          ...user,
          name: toProperCase(user.name),
        }))
      );
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  useEffect(reloadUsers, []);

  return { users, reloadUsers, isLoading, error };
};

export const useFetchUsers = (userIds) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userIds) return;

    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'users';

      const usersRef = collection(db, collectionName);
      const q = query(usersRef, where('id', 'in', userIds));
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => doc.data());
      setUsers(users);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [userIds]);

  return { users, isLoading, error };
};

export async function deleteUser(userId) {
  try {
    const deleteResult = await deleteDoc(doc(db, 'programs', userId));
    return deleteResult;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
}

export const useCountUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'users';
      const coachesRef = collection(db, collectionName);
      const snapshot = await getCountFromServer(coachesRef);
      setCount(snapshot.data().count);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  return { count, isLoading, error };
};

export const useFetchLastUsersStats = (nbDays = 7) => useFetchItemDailyCount('users', 'created_at', nbDays);

export const useFetchEnrollmentStatus = (programId) => {
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [unenrolledUsers, setUnenrolledUsers] = useState([]);
  const [enrolledCount, setEnrolledCount] = useState(0);
  const [unenrolledCount, setUnenrolledCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'programs_registration');
        const enrolledQuery = query(
          usersRef,
          where('program_id', '==', programId)
          // where('status', '==', 'REGISTERED')
        );

        const unenrolledQuery = query(
          usersRef,
          where('program_id', '==', programId),
          where('status', '==', 'CONCLUDED')
        );
        const enrolledSnapshot = await getDocs(enrolledQuery);
        const unenrolledSnapshot = await getDocs(unenrolledQuery);

        const enrolledData = enrolledSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const unenrolledData = unenrolledSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setEnrolledUsers(enrolledData);
        setUnenrolledUsers(unenrolledData);
        setEnrolledCount(enrolledSnapshot.size);
        setUnenrolledCount(unenrolledSnapshot.size);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [programId]);
  return { enrolledUsers, unenrolledUsers, enrolledCount, unenrolledCount, isLoading, error };
};

export const updateLastLogin = async (userId) => {
  const userRef = doc(db, 'coaches', userId);
  const timestamp = Timestamp.now();
  const location = await fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then((data) => `${data.city}, ${data.region}, ${data.country_name}`);
  await updateDoc(userRef, { last_login: { timestamp, location } });
}

export const getUserMinInfos = async (id) => {
  const getForCollection = async (collectionName) => {
    const snapshot = await getDoc(doc(collection(db, collectionName), id));
    if (snapshot.exists()) {
      const { name, image } = snapshot.data();
      return {
        id,
        name,
        image: formatImage(image, { w: 72, h: 72 }),
        role: collectionName
      };
    }
    return null; 
  }

  const admin = await getForCollection('admins');
  if (admin) return admin;
  
  const coach = await getForCollection('coaches');
  if (coach) return coach;

  const user = await getForCollection('users');
  if (user) return user;

  return null;
};

export const savePushNotificationToken = (userId, token) => {
  const coachRef = doc(db, 'coaches', userId);
  updateDoc(coachRef, { push_token: token });
}