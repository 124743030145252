import {useState} from 'react';
// @mui
import {Link, Stack, IconButton, InputAdornment, TextField, Checkbox} from '@mui/material';
import {Alert, LoadingButton} from '@mui/lab';
// components
import {signInWithEmailAndPassword} from "firebase/auth";
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/iconify';
import {auth} from "../../../firebaseConfig";
import { updateLastLogin } from '../../../services/users_service';
// ----------------------------------------------------------------------

export default function LoginForm() {

    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const {t} = useTranslation();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.trim();

        setIsLoading(true);
        setError(null);
        signInWithEmailAndPassword(auth, email, data.get('password'))
            .then((data) => updateLastLogin(data.user.uid))
            .catch(error => setError(error.message))
            .finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                <TextField name="email" label={t("Email address")}/>

                <TextField
                    name="password"
                    label={t('Password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                <Checkbox name="remember" label="Remember me"/>
                <Link variant="subtitle2" underline="hover" href="/reset-password" component="a">
                    {t('Forgot password?')}
                </Link>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={isLoading}>
                {t('Login')}
            </LoadingButton>
        </form>
    );
}
