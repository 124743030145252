import { useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import { CallingState, useCalls, useStreamVideoClient } from "@stream-io/video-react-sdk";
import { enqueueSnackbar, SnackbarProvider, useSnackbar } from "notistack";
import CallNotification from "./call-notification";
import AVCallContext from "../../contexts/AVCallContext";

CallNotificationProvider.propTypes = {
    children: PropTypes.node,
};
export default function CallNotificationProvider({ children }) {
    const client = useStreamVideoClient();

    return (
        <SnackbarProvider
            Components={{
                callNotification: CallNotification
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {client && <CallNotifier />}
            {children}
        </SnackbarProvider>
    );
}

function CallNotifier() {
    const { closeSnackbar } = useSnackbar();
    const { playSound, stopSound, isPlayingSound } = useContext(AVCallContext);
    const calls = useCalls();
    console.log("CallNotifier | Calls", calls)
    const ringingCalls = calls.filter(
        (call) => call.state.callingState === CallingState.RINGING,
    );
    console.log("CallNotifier | Ringing calls", ringingCalls, isPlayingSound)

    if (ringingCalls.length && !isPlayingSound) {
        const last = ringingCalls[ringingCalls.length - 1]
        playSound(!last.isCreatedByMe)
        console.log("CallNotifier | Playing sound for call", last.id, last.isCreatedByMe);
    } else if (!ringingCalls.length && isPlayingSound) {
        console.log("CallNotifier | Stopping sound");
        stopSound()
    }

    useEffect(() => {
        if (!ringingCalls.length) {
            return () => { };
        }

        const snackBarMap = {};
        ringingCalls.forEach((call) => {
            snackBarMap[call.id] = enqueueSnackbar({
                variant: 'callNotification',
                persist: true,
                call,
            });
        });
        
        return () => {
            ringingCalls.forEach((call) => {
                console.log("From CallNotifier", call.state.callingState, CallingState.LEFT, call.state.callingState !== CallingState.LEFT);
                if (call.state.callingState !== CallingState.LEFT) {
                    // call.leave({ reject: true });
                    closeSnackbar(snackBarMap[call.id]);
                }
            });
        };
    }, [closeSnackbar, ringingCalls]);

    return null;
}