import { collection, doc, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebaseConfig';

/*
  Notification Modal
  - title
  - message
  - is_read
  - created_at
  - avatar
  - type: new_message, new_session
  - data:
    [ For new_message
      - count
      - chat_id
    ]
    [ For new_session
      - session_id
    ]
*/

const getUserNotificationsCollection = (user) => {
  const userCollection = user.userRoles.includes('ADMIN') ? 'admins' : 'coaches';
  return collection(db, userCollection, user.id, 'notifications');
};

export const useFetchUserNotifications = (user) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const userId = user?.id;

  const generateLink = (notif) => {
    if (notif.type === 'new_message') return `chats/${notif.data.chat_id}`;
    if (notif.type === 'new_session') return 'sessions';
    return '';
  };

  useEffect(() => {
    if (!userId) return {};

    setIsLoading(true);

    const notifCollection = getUserNotificationsCollection(user);

    const unsubscribe = onSnapshot(
      query(notifCollection, orderBy('created_at')),
      (snapshot) => {
        let _notifications = [];

        snapshot.forEach((doc) => {
          _notifications.push({ id: doc.id, ...doc.data() });
        });

        _notifications = _notifications.map((notif) => ({
          ...notif,
          created_at: notif.created_at?.toDate(),
          link: generateLink(notif),
        }));
        setNotifications(_notifications);
        setIsLoading(false);
      },
      (err) => {
        setError(err);
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [userId]);

  return { notifications, isLoading, error };
};


export const markOneNotificationsAsRead = async (user, notifId) => {
  const notifRef = doc(getUserNotificationsCollection(user.id), notifId);
  await updateDoc(notifRef, { is_read: true });
}


export const markAllNotificationsAsRead = async (user, notifications) => {
  const batch = writeBatch(db);

  notifications.forEach((notif) => {
    const notifRef = doc(getUserNotificationsCollection(user), notif.id);
    batch.update(notifRef, { is_read: true });
  });

  await batch.commit();
}

export const sendNotifications = async (receivers, title, message, type, data) => {
  const batch = writeBatch(db);

  receivers.forEach((receiver) => {
    const notifRef = doc(db, receiver.role, receiver.id, 'notifications', uuidv4());
    batch.set(notifRef, {
      title,
      message,
      is_read: false,
      created_at: Timestamp.now(),
      avatar: null,
      type,
      data,
    });
  });

  await batch.commit();
}

export const sendNewMessageNotifications = async (chat, receivers, senderId) => {
  const targetReceivers = receivers.filter((receiver) => receiver.id !== senderId);

  await sendNotifications(
    targetReceivers,
    'New Message',
    `You have a new message from ${chat.name}`,
    'new_message',
    {
      chat_id: chat.id,
      chat_name: chat.name
    }
  );
  console.log('Sending notifications to', targetReceivers); 
};

export const sendChatAddedNotification = async (chat, receivers) => {
  await sendNotifications(
    receivers,
    'New Chat',
    `You have been added to a new chat: ${chat.name}`,
    'new_message',
    {
      chat_id: chat.id,
      chat_name: chat.name
    }
  );
}